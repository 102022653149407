<template>
  <base-view title="Planeaciones - Listado">

    <check-authorization :requiresAuthorizations="['listar presupuestos']">
      <!-- Modal para crear/actualizar planeación presupuestal -->
    <planeaciones-modal-crear v-model="showModal" :budget="selectedBudget" :isUpdating="isUpdating"></planeaciones-modal-crear>
      <!-- END: Modal para crear/actualizar planeación presupuestal -->

      <!-- Notificacion de alerta -->
      <portal-target name="notificationAlertProyeccionTable" />

      <b-card no-body class="card-header-actions">
        <b-card-header>
          <check-authorization :requiresAuthorizations="['crear presupuesto']">
            <b-button
              size="sm"
              variant="success"
              @click="showCreateBudget()"
            >
              Crear planeación presupuestal
            </b-button>
          </check-authorization>
        </b-card-header>

        <b-card-body class="h-100" style="min-height: 65vh;">
          <div class="px-2">
            <b-overlay :show="isLoading">
              <template #overlay>
                <div class="text-center">
                  <loading message="Cargando información, por favor espere..." />
                </div>
              </template>
              <template :class="{ isLoading }">
                <div class="row my-3 p-3">
                  <div class="col-md-4 mb-4" v-for="budget in getBudgets" :key="budget.id">
                    <b-card class="card lift h-100 my-cursor" @click="goToPlanView(budget.id)">
                      <div class="card-body d-flex justify-content-left flex-row">
                        <div class="d-flex align-items-center mr-3">
                          <i class="feather-xl text-dark" data-feather="layers"></i>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="mr-3">
                            <h5>{{ budget.nombre }}</h5>
                            <p>{{ budget.periodo_fiscal.descripcion }}</p>
                            <p>Descripción: {{  budget.descripcion ? budget.descripcion : 'Sin descripción...' }}</p>
                            <p>Porcentaje: {{ budget.porcentaje ? budget.porcentaje : '0' }}</p>
                            <check-authorization :requiresAuthorizations="['actualizar presupuesto']">
                              <b-button size="sm" variant="primary" @click.stop="selectBudget(budget.id)">Editar</b-button>
                            </check-authorization>
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>
              </template>
            </b-overlay>
          </div>
        </b-card-body>
      </b-card>
    </check-authorization>
  </base-view>
</template>

<script>
import PlaneacionesModalCrear from '@/components/Planeacion/PlaneacionesModalCrear'
// import { mapActions } from 'vuex'

export default {
  name: 'PlaneacionesView',

  components: {
    PlaneacionesModalCrear
  },

  created () {
    this.init()
  },

  data () {
    return {
      showModal: false,
      selectedBudget: null,
      isUpdating: false,
      isLoading: false
    }
  },

  computed: {
    getBudgets () {
      return this.$store.getters['planeacionModule/getBudgets']
    }
  },

  methods: {

    /**
     * Inicializa el componente para seleccionar el periodo fiscal actualmente activo
     */
    init () {
      this.getPlans()
    },

    async getPlans () {
      this.isLoading = true
      await this.$store.dispatch('planeacionModule/getBudgetPlans')
      this.isLoading = false
    },

    selectBudget (value) {
      this.selectedBudget = value
      this.isUpdating = true
      this.showModal = true
    },
    goToPlanView (value) {
      const url = `/planeacion/${value}/importacion`
      console.log(url)
      console.log(this.$router)
      this.$router.push({ path: url })
    },

    showCreateBudget () {
      this.selectedBudget = null
      this.isUpdating = false
      this.showModal = true
    }
  }
}
</script>
